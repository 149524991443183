import marked from 'marked'

const transformMDtoHTML = (text) => {
  const splitedText = text.split('\n')
  const paragraphs = splitedText
    .map((p) => `<p>${marked.parseInline(p)}</p>`)
    .join('')

  return paragraphs
}

export const transformMDtoText = (text) => {
  return marked.parseInline(text)
}

export default transformMDtoHTML
